<template>
    <div>
        <aw-tabler :table="table">
            <template #filter>
                <el-form ref="formRef" :model="table.filter" :inline="true">
                    <el-form-item label="文件名称:">
                        <el-input v-model="table.filter.filename" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-select v-model="table.filter.status" placeholder="请选择">
                            <el-option v-for="(item, index) in dict.export.export_status" :key="index" :label="item" :value="index" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </template>
        </aw-tabler>
    </div>
</template>
<script setup lang="jsx">
import { http, util, dict } from '@/assets/js'
import { reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import { ElMessage } from 'element-plus'

const route = useRoute()
const table = reactive({
    title: '我的导出',
    select: false,
    loading: false,
    filter: {
        filename: '',
        status: ''
    },
    datas: [],
    columns: [
        ...tableColumn([
            { title: 'ID', key: 'id', minWidth: '100px' },
            { title: '文件名', key: 'filename', minWidth: '100px' },
            {
                title: '状态',
                key: 'status',
                width: 150,
                tag: (data) => {
                    if (data.status) return [{ text: dict.export.export_status[data.status], props: { type: dict.export.export_status_type[data.status] } }]
                }
            },
            { title: '发起导出时间', key: 'addtime', minWidth: '100px', time: true },
            { title: '完成导出时间', key: 'endtime', minWidth: '100px', time: true }
        ]),
        {
            fixed: 'right',
            title: '操作',
            key: 'operate',
            minWidth: '160px',
            buttons: [
                {
                    name: '下载',
                    props: { type: 'primary', size: 'small' },
                    show: (data) => data.status == 3,
                    action: (data) => {
                        window.open(data.download)
                    }
                }
            ]
        }
    ],
    page: {
        total: 0,
        page: 1,
        page_size: 20,
        sizeChange: (val) => {
            table.page.page_size = val
            table.action(table.filter)
        },
        currentChange: (val) => {
            table.page.page = val
            table.action(table.filter)
        }
    },
    action: async (params) => {
        table.loading = true
        let page = { page: table.page.page, page_size: table.page.page_size }
        let result = await http.get('/agent/Member/exportList', { params: { ...params, ...route.query, ...page } })
        table.datas = result.data
        table.page.total = result.total || Infinity
        table.page.page = result.current_page
        table.page.page_size = result.per_page
        table.loading = false
        return
    },
    extras: [
        {
            name: '刷新列表',
            props: { type: 'warning', size: 'small' },
            action: () => {
                table.page.currentChange(1)
            }
        }
    ]
})
</script>
<style scoped></style>
